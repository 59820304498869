import React from 'react'
import { useEffect , useState } from 'react';

const useCutShapes = () => {
    const [cuts, setcuts] = useState([]);
    const [shapes, setshapes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchcutshapes = async () => {
        try {
            const response = await fetch('https://backend.amjgems.com/api/shapes-cuts', {
                method: 'GET',
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setcuts(data.cuts);
            setshapes(data.shapes)
            setError(null);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchcutshapes();
    }, []);

    return { cuts, shapes, loading, error, fetchcutshapes };
}

export default useCutShapes