import React from 'react'
import { useState , useEffect } from 'react';

const ShowAboutUs = () => {
    const [aboutus, setaboutus] = useState([]);
    const [load, setLoad] = useState(true);
    const [err, setErr] = useState(null);

    const fetchaboutus = async () => {
        try {
            const response = await fetch(`https://backend.amjgems.com/api/about-us`, {
                method: 'GET',
            }); // Replace with your API endpoint
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setaboutus(data);
            setErr(null);
        } catch (error) {
            setErr(error.message);
        } finally {
            setLoad(false);
        }
    };

    useEffect(() => {
      fetchaboutus();
    }, []);

    return { aboutus, load, err, fetchaboutus };
}

export default ShowAboutUs