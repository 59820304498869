import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import LoginModal from '../Mainpage/LoginModal';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const AddReviewModal = ({ show, onClose, product_id }) => {
    const [loginShow, setLoginShow] = useState(false);
    const [review, setReview] = useState('');
    const [stars, setStars] = useState(1);
    const [review_image_1, setReviewImage1] = useState(null);
    const [review_image_2, setReviewImage2] = useState(null);

    const handleCloseLogin = () => {
        setLoginShow(false);
    };

    const handleShowLogin = () => {
        setLoginShow(true);
    };

    const handleSubmitReview = async (event) => {
        event.preventDefault();

        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') || '';

        const formData = new FormData();
        formData.append('rating', stars);
        formData.append('id', user_id);
        formData.append('review', review);
        formData.append('product_id', product_id);

        // Append images if they exist
        formData.append('review_image_1', review_image_1 || '');
        formData.append('review_image_2', review_image_2 || '');

        // Debugging logs
        console.log('Form Data Entries:');
        for (let [key, value] of formData.entries()) {
            console.log(key, value);
        }
        console.log(formData);

        try {
            const response = await fetch('https://backend.amjgems.com/api/add-review', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`, // Use only Authorization header for multipart/form-data
                },
                body: formData,
            });

            const data = await response.json();
            console.log('Response Data:', data);
            onClose(); // Close modal on success

        } catch (error) {
            console.error('Error adding review:', error);
            onClose();

            if (!token || !user_id) {
                localStorage.clear();
                cookies.remove('user_id');
                handleShowLogin();
            }
        }
    };

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a Review</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="popupcontainer">
                        <div className="left-side">
                            <h1>Review</h1>
                            <form className='reviewform' onSubmit={handleSubmitReview}>
                                <textarea
                                    className="styled-textarea"
                                    placeholder='Write a Review'
                                    value={review}
                                    onChange={(e) => setReview(e.target.value)}
                                    style={{ width: '100%', height: '115px' }} // Adjust width and height as needed
                                />
                                <h6 style={{ marginTop: '1rem' }}>Add Product Image 1:</h6>
                                <input
                                    type="file"
                                    className='form-control'
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        console.log('Selected Image 1:', file);
                                        setReviewImage1(file);
                                    }}
                                    style={{ marginBottom: '3px' }}
                                />
                                <h6 style={{ marginTop: '1rem' }}>Add Product Image 2:</h6>
                                <input
                                    type="file"
                                    className='form-control'
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        console.log('Selected Image 2:', file);
                                        setReviewImage2(file);
                                    }}
                                    style={{ marginBottom: '3px' }}
                                />
                                <div style={{ marginTop: '5px' }}>
                                    {[1, 2, 3, 4, 5].map((star) => (
                                        <span
                                            key={star}
                                            onClick={() => setStars(star)}
                                            className={star <= stars ? "starx" : "starr"}
                                        >
                                            ★
                                        </span>
                                    ))}
                                </div>
                                <div id='butdiv'>
                                    <button className='loginsignupbutton' type='submit'>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <LoginModal show={loginShow} onClose={handleCloseLogin} />
        </>
    );
};

export default AddReviewModal;
