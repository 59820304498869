import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search } from 'react-bootstrap-icons';

const SearchBox = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsVisible, setSuggestionsVisible] = useState(false);

    const navigate = useNavigate();


    const fetchSuggestions = async (query) => {
        if (!query) {
            setSuggestions([]); // Clear suggestions if input is empty
            setSuggestionsVisible(false); // Hide suggestions
            return; // Exit the function early
        }

        try {
            const response = await fetch(`https://backend.amjgems.com/api/products/suggestion/${encodeURIComponent(query)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data);
            setSuggestions(data.products);
            setSuggestionsVisible(data.products.length > 0); // Show suggestions only if there are products
        } catch (error) {
            console.error(error);
            setSuggestions([]); // Clear suggestions on error
            setSuggestionsVisible(false);
        }
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);

        if (value) {
            fetchSuggestions(value);
        } else {
            setSuggestions([]); // Clear suggestions if input is empty
            setSuggestionsVisible(false); // Hide suggestions
        }
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        const trimmedQuery = searchQuery.trim();
        if (trimmedQuery) {
            setSearchQuery('');
            setSuggestions([]);
            setSuggestionsVisible(false);
            navigate(`/search/${trimmedQuery}`);
        }
        else {
            setSearchQuery('');
            setSuggestions([]);
            setSuggestionsVisible(false);
            navigate('/allproducts')
        }
    }

    const search = (query) => {
        const trimmedQuery = query.trim();
        if (trimmedQuery) {
            setSearchQuery('');
            setSuggestions([]);
            navigate(`/search/${trimmedQuery}`);
        }
        else {
            setSearchQuery('');
            setSuggestions([]);
            navigate('/allproducts')
        }
    }

    return (
        <div class="searchcontainer">
            <div class="search-bar">
                <form onSubmit={handleSearchSubmit}>
                    <input type="text" class="search-input" placeholder="Search..." value={searchQuery}
                        onChange={handleInputChange} />
                    <button id='searchbutton' type='submit'><Search size={20} /></button>
                </form>
                {searchQuery != '' && suggestions.length > 0 && suggestionsVisible && (
                    <div className="suggestions-box">
                        {suggestions.map((suggestion, index) => (
                            <div
                                key={index}
                                className="suggestion-item"
                                onClick={() => {
                                    setSearchQuery(suggestion.product_name);
                                    search(suggestion.product_name);
                                }}
                            >
                                {suggestion.product_name}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default SearchBox