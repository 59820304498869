
import React, { useState, useEffect, useRef } from 'react';
import useCutShapes from '../Admin/useCutShapes';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import useCategories from '../Admin/useCategories';

import '../Admin/Admin.scss';

const Categorysection = () => {
  const { cuts, shapes, loading, error, fetchcutshapes } = useCutShapes();
  const { categories, loding, err, fetchcategories } = useCategories();
  const [isCutsDropdownVisible, setIsCutsDropdownVisible] = useState(false);
  const [isShapesDropdownVisible, setIsShapesDropdownVisible] = useState(false);
  const [isCategoriesDropdownVisible, setIsCategoriesDropdownVisible] = useState(false);
  const location = useLocation();
  const isLandingPage = location.pathname === '/';
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 814);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 814);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Refs to detect clicks outside the dropdowns
  const cutsRef = useRef(null);
  const shapesRef = useRef(null);
  const categoriesRef = useRef(null);
  const dropdownsRef = useRef(null);

  // Show dropdowns
  const toggleCutsDropdown = () => setIsCutsDropdownVisible(true);
  const toggleShapesDropdown = () => setIsShapesDropdownVisible(true);
  const toggleCategoriesDropdown = () => setIsCategoriesDropdownVisible(true);

  // Hide dropdowns
  const hideCutsDropdown = () => setIsCutsDropdownVisible(false);
  const hideShapesDropdown = () => setIsShapesDropdownVisible(false);
  const hideCategoriesDropdown = () => setIsCategoriesDropdownVisible(false);

  // Handle click outside to close dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        cutsRef.current && !cutsRef.current.contains(event.target) &&
        shapesRef.current && !shapesRef.current.contains(event.target) &&
        categoriesRef.current && !categoriesRef.current.contains(event.target) &&
        dropdownsRef.current && !dropdownsRef.current.contains(event.target)
      ) {
        setIsCutsDropdownVisible(false);
        setIsShapesDropdownVisible(false);
        setIsCategoriesDropdownVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div id="categories">
        {/* All Products dropdown */}
        <span
          ref={categoriesRef}
          onMouseEnter={() => {
            if (!isCategoriesDropdownVisible) {
              toggleCategoriesDropdown();
            }
            hideCutsDropdown();
            hideShapesDropdown();
          }}
          onMouseLeave={() => {
            hideCategoriesDropdown();
          }}
        >
          All Products
        </span>

        {/* Cuts dropdown */}
        <span
          ref={cutsRef}
          onMouseEnter={() => {
            if (!isCutsDropdownVisible) {
              toggleCutsDropdown();
            }
            hideShapesDropdown();
            hideCategoriesDropdown();
          }}
          onMouseLeave={() => {
            hideCutsDropdown();
          }}
        >
          Cut's
        </span>

        {/* Shapes dropdown */}
        <span
          ref={shapesRef}
          onMouseEnter={() => {
            if (!isShapesDropdownVisible) {
              toggleShapesDropdown();
            }
            hideCutsDropdown();
            hideCategoriesDropdown();
          }}
          onMouseLeave={() => {
            hideShapesDropdown();
          }}
        >
          Shape's
        </span>
      </div>

      {/* Render categories dropdown if visible */}
      {isCategoriesDropdownVisible && (!isLandingPage || !isMobile) && (
        <div
          ref={dropdownsRef}
          className="dropdown-menux"
          id="dropdown-menu-categories"
          onMouseEnter={() => {
            toggleCategoriesDropdown();
          }}
          onMouseLeave={() => {
            hideCategoriesDropdown();
          }}
        >
          <span><Link className='linkLandingpage' to={`/allproducts`}>All Products</Link></span>
          {categories.map((category, index) => (
            <span key={index}>
              <Link
                onClick={() => {
                  setIsCutsDropdownVisible(false);
                  setIsShapesDropdownVisible(false);
                  setIsCategoriesDropdownVisible(false);
                }}
                className="link" to={`/category/${category.category_code}`}>
                {category.category_name}
              </Link>
            </span>
          ))}
        </div>
      )}

      {/* Render cuts dropdown if visible */}
      {isCutsDropdownVisible && (!isLandingPage || !isMobile) && (
        <div
          ref={dropdownsRef}
          className="dropdown-menux"
          id="dropdown-menu-cuts"
          onMouseEnter={() => {
            toggleCutsDropdown();
          }}
          onMouseLeave={() => {
            hideCutsDropdown();
          }}
        >
          {cuts.map((cut, index) => (
            <span key={index}>
              <Link
                onClick={() => {
                  setIsCutsDropdownVisible(false);
                  setIsShapesDropdownVisible(false);
                  setIsCategoriesDropdownVisible(false);
                }}
                className="link" to={`/cut/${cut.cut}`}>
                {cut.cut}
              </Link>
            </span>
          ))}
        </div>
      )}

      {/* Render shapes dropdown if visible */}
      {isShapesDropdownVisible && (!isLandingPage || !isMobile) && (
        <div
          ref={dropdownsRef}
          className="dropdown-menux"
          id="dropdown-menu-shapes"
          onMouseEnter={() => {
            toggleShapesDropdown();
          }}
          onMouseLeave={() => {
            hideShapesDropdown();
          }}
        >
          {shapes.map((shape, index) => (
            <span key={index}>
              <Link
                onClick={() => {
                  setIsCutsDropdownVisible(false);
                  setIsShapesDropdownVisible(false);
                  setIsCategoriesDropdownVisible(false);
                }}
                className="link" to={`/shape/${shape.shape}`}>
                {shape.shape}
              </Link>
            </span>
          ))}
        </div>
      )}

      {/* Render categories dropdown if visible on landing page */}
      {isCategoriesDropdownVisible && isLandingPage && (
        <div
          ref={dropdownsRef}
          className="dropdown-menux-lpage-phone"
          id="dropdown-menu-categories"
          onMouseEnter={() => {
            toggleCategoriesDropdown();
          }}
          onMouseLeave={() => {
            hideCategoriesDropdown();
          }}
        >
          <span><Link className='linkLandingpage' to={`/allproducts`}>All Products</Link></span>
          {categories.map((category, index) => (
            <span key={index}>
              <Link
                onClick={() => {
                  setIsCutsDropdownVisible(false);
                  setIsShapesDropdownVisible(false);
                  setIsCategoriesDropdownVisible(false);
                }}
                className="link" to={`/category/${category.category_code}`}>
                {category.category_name}
              </Link>
            </span>
          ))}
        </div>
      )}
      {/* Render cuts dropdown if visible */}
      {isCutsDropdownVisible && isLandingPage && (
        <div
          ref={dropdownsRef}
          className="dropdown-menux-lpage-phone"
          id="dropdown-menu-cuts"
          onMouseEnter={() => {
            toggleCutsDropdown();
          }}
          onMouseLeave={() => {
            hideCutsDropdown();
          }}
        >
          {cuts.map((cut, index) => (
            <span key={index}>
              <Link
                onClick={() => {
                  setIsCutsDropdownVisible(false);
                  setIsShapesDropdownVisible(false);
                }}
                className="link" to={`/cut/${cut.cut}`}>
                {cut.cut}
              </Link>
            </span>
          ))}
        </div>
      )}

      {/* Render shapes dropdown if visible */}
      {isShapesDropdownVisible && isLandingPage && (
        <div
          ref={dropdownsRef}
          className="dropdown-menux-lpage-phone"
          id="dropdown-menu-shapes"
          onMouseEnter={() => {
            toggleShapesDropdown();
          }}
          onMouseLeave={() => {
            hideShapesDropdown();
          }}
        >
          {shapes.map((shape, index) => (
            <span key={index}>
              <Link onClick={() => {
                setIsCutsDropdownVisible(false);
                setIsShapesDropdownVisible(false);
              }}
                className="link" to={`/shape/${shape.shape}`}>
                {shape.shape}
              </Link>
            </span>
          ))}
        </div>
      )}


    </>
  );
};

export default Categorysection;


