import React from 'react'
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import loginimage from '../../images/amazing gems and jewellery png with name.png';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Swal from 'sweetalert2';
import Cookies from 'universal-cookie'; // Ensure this is installed
import { jwtDecode } from 'jwt-decode'; // Correct import

const GuestLoginModal = ({ show, onClose }) => {
    const [email, setemail] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [name, setname] = useState('');
    const [otp, setOtp] = useState('');
    const [error, setError] = useState(null);

    const handleSubmitGuestlogin = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://backend.amjgems.com/api/guest-register', { // Replace with your API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: name, email: email, contact_no: contactNo }),
            });
            const data = await response.json();
            console.log(data);

            // Store token and user ID in local storage
            if (data.token && data.user_id) {
                localStorage.setItem('token', data.token);
                localStorage.setItem('user_id', data.user_id);
                localStorage.setItem('user_name', data.user_name);

                const decodedToken = jwtDecode(data.token);
                const expiryDate = new Date(decodedToken.exp * 1000); // Convert from seconds to milliseconds

                // Set cookie with user ID
                const cookies = new Cookies();
                cookies.set('user_id', data.user_id, { expires: expiryDate });

                onClose();
                Swal.fire({
                    title: 'Guest Login Successful!',
                })
            } else {
                console.error('Token or UserId missing in response');
                onClose();
                Swal.fire({
                    title: 'Login Failed!' + (data.message || 'Unknown error'),
                })
            }

        } catch (error) {
            console.error('Error adding category:', error);
        }
    }

    const handleVerifyEmail = async () => {
        if (email && name && contactNo) {
            try {
                const response = await fetch('https://backend.amjgems.com/api/send-otp', { // Replace with your API endpoint
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email,
                    }),
                });

                const data = await response.json();

                if (!response.ok) {
                    // Handle errors (e.g., validation errors) from the response
                    setError(data);
                    Swal.fire({
                        title: 'Failed to sign up: ' + (data.message || 'Unknown error'),
                    })
                    console.log(error);
                    return;
                }

                setError(null);
                // Show the OTP form after verifying the email
                const verifyEmailButton = document.querySelector('.verifyEmail');
                const otpForm = document.querySelector('#fromVerifyEmail');
                const failed = document.querySelector('.resendOtp');

                if (verifyEmailButton) {
                    verifyEmailButton.style.display = 'none'; // Hide the verify button
                }

                if (otpForm) {
                    otpForm.style.display = 'block'; // Show the OTP form
                }
                if (failed) {
                    failed.style.display = 'none'; // Show the OTP form
                }


            } catch (error) {
                setError('An error occurred while signing up.');
                console.error('Error:', error);
            }
        }
        else {
            Swal.fire({
                title: 'Fill All Fields: ',
            })
        }
    }

    const handleVerifyOtpSubmit = async (e) => {
        e.preventDefault();
        if (otp) {
            try {
                const response = await fetch('https://backend.amjgems.com/api/verify-otp', { // Replace with your API endpoint
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email,
                        otp
                    }),
                });

                const data = await response.json();

                if (!response.ok) {
                    // Handle errors (e.g., validation errors) from the response
                    setError(data);
                    Swal.fire({
                        title: 'Failed to Verify !',
                    })
                    const failed = document.querySelector('.resendOtp');
                    const otpForm = document.querySelector('#fromVerifyEmail');

                    if (otpForm) {
                        otpForm.style.display = 'none'; // Show the OTP form
                    }

                    if (failed) {
                        failed.style.display = 'block'; // Show the OTP form
                    }
                    setOtp('');
                    return;
                }

                console.log(data);
                setError(null);
                // Show the OTP form after verifying the email
                const otpForm = document.querySelector('#fromVerifyEmail');

                const ss = document.querySelector('.SignupSubmitButton');
                const vv = document.querySelector('#otpVerifiedMsg');

                if (otpForm) {
                    otpForm.style.display = 'none'; // Show the OTP form
                }
                if (ss) {
                    ss.style.display = ''; // Show the OTP form
                }
                if (vv) {
                    vv.style.display = 'block'; // Show the OTP form
                }


            } catch (error) {
                setError('An error occurred while signing up.');
                console.error('Error:', error);
            }


        }
        else {
            Swal.fire({
                title: 'Fill OTP',
            })
        }

    }

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="popupcontainerls">

                        <img id='loginsignupimage' src={loginimage} alt="Login" />


                        <div className="popupcontainer">
                            <h1>Guest Login </h1>
                            <form onSubmit={handleSubmitGuestlogin}>
                                <input
                                    className='loginsignupinput'
                                    type="text"
                                    placeholder='Enter Name'
                                    value={name}
                                    onChange={(e) => {
                                        setname(e.target.value)
                                    }}
                                    required
                                />
                                <input
                                    className='loginsignupinput'
                                    type="email"
                                    placeholder='E-mail'
                                    value={email}
                                    onChange={(e) => {
                                        setemail(e.target.value)
                                    }}
                                    required
                                />
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={contactNo}
                                    onChange={setContactNo} // Directly use the value returned
                                    className="form-input"
                                    required
                                />
                                <p className="discount" id='otpVerifiedMsg' style={{ display: 'none', textAlign: 'center' }}>OTP Verified!</p>
                                <div id='butdiv' className='SignupSubmitButton' style={{ display: 'none' }}>
                                    <button className="product-button" type='submit'>Submit</button>
                                </div>
                            </form>
                            <div id='butdiv' className='resendOtp' style={{display:'none'}}>
                                <button className="product-button" onClick={() => { handleVerifyEmail() }}>Re-send OTP</button>
                            </div>
                            <div id='butdiv' className='verifyEmail'>
                                <button className="product-button" onClick={() => { handleVerifyEmail() }}>verify Email</button>
                            </div>
                            <form id='fromVerifyEmail' style={{ display: 'none' }} onSubmit={handleVerifyOtpSubmit}>
                                <input
                                    style={{ border: '2.2px solid black' }}
                                    className='admininput'
                                    type="text"
                                    value={otp}
                                    placeholder='Enter OTP'
                                    onChange={(e) => setOtp(e.target.value)}
                                    required
                                />
                                <button className="product-button">Verify</button>
                            </form>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    )

}

export default GuestLoginModal