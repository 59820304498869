import React from 'react'
import './midcontent.scss'
import useProducts from '../Admin/useProducts';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Filtercontroller from './Filtercontroller';
import { Link } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { useLocation } from 'react-router-dom';
const Home = () => {

  const location = useLocation(); // Get current location
  const isRootPath = location.pathname === '/'; // Check if we're on the root path
  const { category_code } = useParams();
  const { cut } = useParams();
  const { shape } = useParams();

  const [filters, setFilters] = useState({
    category_code: category_code || '',
    cut: cut || '',
    shape: shape || '',
    priceRange: '',
    min_price: null,
    max_price: null,
    per_page: '',
  });

  const { products, currentPage, lastPage, wishlistElements, load, err, fetchproducts } = useProducts(filters);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [products]);

  useEffect(() => {
    // Reset filters if we're on the allproducts page

    setFilters(prevFilters => ({
      ...prevFilters,
      category_code: category_code || '', // Default to '' if category_code is null
      cut: cut || '', // Default to '' if cut is null
      shape: shape || '', // Default to '' if shape is null
    }));
  }, [category_code, cut, shape]); // Trigger reset when path changes to allproducts

  useEffect(() => {

    fetchproducts();

  }, [filters]);


  const generatePageNumbers = (currentPage, lastPage) => {
    const pages = [];
    const maxVisiblePages = 5;

    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(lastPage, currentPage + 2);

    // Add the first page
    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) pages.push('...'); // Add ellipsis if needed
    }

    // Add range of pages
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // Add the last page
    if (endPage < lastPage) {
      if (endPage < lastPage - 1) pages.push('...'); // Add ellipsis if needed
      pages.push(lastPage);
    }

    return pages;
  };

  const handlePageClick = (pageNumber) => {
    fetchproducts(pageNumber); // Fetch products for the selected page
  };


  if (load) {
    // Render nothing or a loading indicator while the check is in progress
    return <div><Loader /></div>;
  }

  return (
    <>
      {/* Only render Filtercontroller if we're not on the root path */}
      {!isRootPath && <Filtercontroller currentFilters={filters} setFilters={setFilters} />}
      <section id='midcontent'>
        {products.map((product) => (
          <Link className='link' to={`/productshow/${product.product_code}`}>
            <div key={product.id} class="card">
              {wishlistElements.includes(product.product_code) && (
                <button id='wishlistoutside'>
                  ❤
                </button>
              )}
              <div className='image-container'>
                <img src={`https://backend.amjgems.com/${product.product_image_1}`} alt="Captivating Elegance Diamond Earrings" />
              </div>
              <h3>{product.product_name}</h3>
              <p class="price">${(product?.variant?.price * (1 - product.product_discount / 100)).toFixed(2)}{product?.product_discount && (<span class="price-cut">${product?.variant?.price}</span>)}</p>
              {product?.variant?.quantity === 0 && product?.single_product > 0 ?
                (<p class="discount">Out of stock!</p>) :
                (<>{product?.variant?.quantity < 15 && product?.single_product > 0 && <p class="discount">Only {product?.variant?.quantity} left!</p>}</>)
              }
            </div>
          </Link>
        ))}
      </section>
      <div className="pagination">
        {generatePageNumbers(currentPage, lastPage).map((pageNumber, index) => (
          <button
            key={index}
            onClick={() => typeof pageNumber === 'number' && handlePageClick(pageNumber)}
            disabled={pageNumber === currentPage} // Optionally disable the current page button
          >
            {pageNumber}
          </button>
        ))}
      </div>
    </>

  )
}

export default Home