import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Filtercontroller.scss';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import useCutShapes from '../Admin/useCutShapes';
import useCategories from '../Admin/useCategories';
import { useNavigate } from 'react-router-dom';

const Filtercontroller = ({ currentFilters, setFilters }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [priceRange, setPriceRange] = useState(50); // State for price range (default set to $50)
  const [selectedCut, setSelectedCut] = useState(''); // State for selected cut
  const [selectedShape, setSelectedShape] = useState(''); // State for selected shape
  const [selectedCategory, setSelectedCategory] = useState(''); // State for selected category

  const filterContainerRef = useRef(null); // Reference for the filter container
  const filterButtonRef = useRef(null); // Reference for the filter button
  const { cuts, shapes, loading: cutsLoading, error: cutsError } = useCutShapes();
  const { categories, loading: categoriesLoading, error: categoriesError } = useCategories();
  const navigate = useNavigate();

  // Toggle visibility of the filter container
  const toggleFilter = () => setIsVisible((prevState) => !prevState);

  // Close filter container when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close filter if clicked outside of the filter container or the button
      if (
        filterContainerRef.current &&
        !filterContainerRef.current.contains(event.target) &&
        !filterButtonRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Handle change in price range slider
  const handlePriceChange = (event) => {
    setPriceRange(event.target.value);
  };

  // Handle change in selected cut
  const handleCutChange = (event) => {
    setSelectedCut(event.target.value);
  };

  // Handle change in selected shape
  const handleShapeChange = (event) => {
    setSelectedShape(event.target.value);
  };

  // Handle change in selected category
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const submitFilter = () => {
    setIsVisible(false);
    setFilters(prevFilters => ({
      ...prevFilters,
      category_code: selectedCategory || '', // Default to '' if category_code is null
      cut: selectedCut || '', // Default to '' if category_code is null
      shape: selectedShape || '', // Default to '' if category_code is null
      priceRange: priceRange || ''
    }));
  }

  if (cutsLoading || categoriesLoading) {
    return <div>Loading...</div>;
  }

  if (cutsError || categoriesError) {
    return <div>Error loading data!</div>;
  }

  return (
    <div id="filtercontroller">
      <div id='home'>
        <Link className="link" to="/">
          Home
        </Link>
      </div>

      <div id="filter" ref={filterButtonRef}>
        {/* Button to toggle filter visibility */}
        <button className="btn btn-primary" onClick={toggleFilter}>
          Filter
        </button>

        {/* Filter options container */}
        {isVisible && (
          <div ref={filterContainerRef} className="filter-container shadow-lg p-3">
            <div className="filter-option">
              <label>Cuts</label>
              <select className="form-control" value={selectedCut} onChange={handleCutChange}>
                <option value="">Select a Cut</option>
                {cuts.map((cut) => (
                  <option key={cut.id} value={cut.cut}>
                    {cut.cut}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-option">
              <label>Shapes</label>
              <select className="form-control" value={selectedShape} onChange={handleShapeChange}>
                <option value="">Select a Shape</option>
                {shapes.map((shape) => (
                  <option key={shape.id} value={shape.shape}>
                    {shape.shape}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-option">
              <label>Price</label>
              <div>
                {/* Price slider (range input) */}
                <input
                  type="range"
                  min="0"
                  max="500"
                  value={priceRange}
                  onChange={handlePriceChange}
                  className="form-control-range"
                />
                <div className="price-range-text">
                  <span>Under ${priceRange}</span>
                </div>
              </div>
            </div>
            <div className="filter-option">
              <label>Category</label>
              <select className="form-control" value={selectedCategory} onChange={handleCategoryChange}>
                <option value="">Select a Category</option>
                {categories.map((category) => (
                  <option key={category.category_code} value={category.category_code}>
                    {category.category_name}
                  </option>
                ))}
              </select>
            </div>
            <button className="apply-button" onClick={submitFilter}>
              Apply
            </button>

          </div>
        )}
      </div>
    </div>
  );
};

export default Filtercontroller;
