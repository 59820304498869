import React from 'react';
import './Loader.scss'; // Import the CSS for the loader
import loderimg from '../../images/loaderr.gif'

const Loader = () => {
    return (
        <div className="loader">
            <img src={loderimg} alt="" />
        </div>
    );
};

export default Loader;
