import React, { useState, useEffect } from 'react';
import './Profile.scss';
import EditProfileModal from './EditProfileModal';
import { Link } from 'react-router-dom';

const Profile = () => {
    const [userdata, setUserdata] = useState({});
    const [Editshow, setProfileEdit] = useState(false);

    useEffect(() => {
        // Define the async function inside useEffect
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';

            try {
                const response = await fetch(`https://backend.amjgems.com/api/user/${user_id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Using Bearer token authentication
                    },
                });
                const data = await response.json();
                setUserdata(data.user);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (Editshow == false) {
            fetchUserData();
        }

        // Optionally add cleanup logic if needed
        return () => {
            // Cleanup code here if necessary
        };
    }, [Editshow]); // Empty dependency array to run once on mount

    const handleClose = () => {
        setProfileEdit(false);
    };
    const handleEditProfile = (e) => {
        setProfileEdit(true);
    }

    return (
        <div className="profile-container">
            <div className="profile-card">
                <h3 className="profile-heading">Your Profile</h3>
                <h5 className="profile-name">User Name :  {userdata.name}</h5>
                <p className="profile-email">E-mail :  {userdata.email}</p>
                <p className="profile-email">Contact Number :  {userdata.contact_no}</p>
                <p className="profile-email">Address :  {userdata.address}</p>
                <p className="profile-email">Country :  {userdata.country}</p>
                <p className="profile-email">State :  {userdata.state}</p>
                <p className="profile-email">City :  {userdata.city}</p>
                <p className="profile-email">Pincode :  {userdata.pincode}</p>
            </div>
            <div className="profile-card" id='profile-buts'>
                <button onClick={handleEditProfile} className="profile-button">Edit Profile </button>
                <Link to="your-orders">
                    <button className="profile-button">Your Orders</button>
                </Link>
            </div>
            <EditProfileModal show={Editshow} onClose={handleClose} />
        </div>
    );
};

export default Profile;
