import React from 'react'
import './CheckOut.scss'

const CheckOutHeader = () => {
  return (
    <div className='checkoutheaderclass'>
        <div id='checkoutheader'>CHECK OUT</div>
    </div>
  )
}

export default CheckOutHeader