import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './order.scss';

const Order = () => {
  const [order, setOrder] = useState(null); // Start as null to represent loading
  const [error, setError] = useState(null);
  const { order_id } = useParams();

  const fetchOrder = async () => {
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('user_id') || '';

    const apiEndpoint = `https://backend.amjgems.com/api/fetch-order-details`;

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ user_id: user_id, order_id: order_id ? order_id : '' })
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Success:', result);
        setOrder(result.data[0]); // Access the first element of the array
      } else {
        const errorData = await response.json();
        console.error('Error:', errorData);
        setError(errorData.message);
      }
    } catch (error) {
      console.error('Network error:', error);
      setError('Network error: ' + error.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchOrder();
  }, []);

  // Early return for error handling
  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  // Early return if order details are not yet loaded
  if (!order) {
    return <div>Loading...</div>;
  }

  return (
    <div className="order-details-container">
      <h2>Order Summary</h2>
      <div className="order-info">
        <h3>Order ID: {order.order_details.order_id}</h3>
        <p>User ID: {order.order_details.user_id}</p>
        <p>Payment Method: {order.order_details.payment_method}</p>
        <p>Payment Status: <span className={order.order_details.payment_status.toLowerCase()}>{order.order_details.payment_status}</span></p>
        <p>Order Status: <span className={order.order_details.order_status.toLowerCase()}>{order.order_details.order_status}</span></p>
      </div>

      <h3>Order Items</h3>
      <div className="order-items">
        {order.order_items.map(item => (
          <div className="order-item" key={item.id}>
            <h5>{item.product_name}</h5>
            <p>Price: ${item.product_price}</p>
            <p>Quantity: {item.product_quantity}</p>
            <p>Variant: {item.variant_name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Order;
